import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
export default {
  name: "StdLevel",
  data: function data() {
    return {
      // 筛选
      filter: [{
        placeholder: '标准结构状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '标准结构名称',
        value: '',
        type: 'text',
        list: []
      }],
      postListData: {
        name: '',
        state: '',
        pageSize: '',
        pageIndex: ''
      },
      // 用户信息
      userInfo: this.Ls.getParseToken("nof_userInfo"),
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      direction: "rtl",
      drawerState: "",
      drawerData: {
        name: "",
        value: "",
        rank: "",
        state: "",
        createdAt: ""
      },
      // dialog弹框-删除弹框
      delId: "",
      centerDialogVisible: false // 删除弹框显示隐藏
    };
  },

  watch: {},
  components: {},
  created: function created() {
    //获取数据
    this.initData();
  },
  methods: {
    // 添加函数
    addFun: function addFun() {
      this.drawerState = "add";
      this.drawer = true;
    },
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.postListData.pageSize = this.pageSize;
      this.postListData.pageIndex = this.currentPage;
      this.service.axiosUtils({
        requestName: "getStdLevelList",
        data: this.postListData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this.tableData = res.data.data;
            _this.total = res.data.total;
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this2 = this;
      var rowState = "";
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: "changeStdLevelState",
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this2.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    // table-中操作函数
    detail: function detail(type, val) {
      if (type === "edit") {
        this.drawerState = "edit";
        this.drawer = true;
        this.drawerData = {
          name: val.name,
          value: val.value,
          rank: val.rank,
          state: val.state,
          id: val.id
        };
      } else if (type === "del") {
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else {}
    },
    // drawer抽屉-取消函数
    cancelClick: function cancelClick() {
      this.$nextTick(function () {
        this.$refs["form"].resetFields();
      });
      this.drawer = false;
    },
    // drawer抽屉-确定函数
    confirmClick: function confirmClick(formName) {
      var that = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = "";
          that.drawerData.userId = that.userInfo.userId;
          that.drawerData.roleId = that.userInfo.roleId;
          if (that.drawerState === "edit") {
            //修改
            url = "editStdLevel";
          } else {
            //添加
            url = "addStdLevel";
          }
          that.service.axiosUtils({
            requestName: url,
            data: that.drawerData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                that.drawerData = {
                  name: "",
                  value: "",
                  rank: "",
                  state: ""
                };
                if (that.drawerState === "add") {
                  that.currentPage = 1;
                }
                that.$nextTick(function () {
                  that.$refs["form"].resetFields();
                });
                that.initData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this3 = this;
      //删除当前的标准结构
      this.service.axiosUtils({
        requestName: "deleteStdLevel",
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this3.$toast.success(res.message);
            _this3.delId = "";
            _this3.centerDialogVisible = false;
            _this3.initData();
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
    },
    // 筛选按钮函数
    search: function search(val) {
      var that = this;
      if (this.$common.checkSearchOrAllFilter(val)) {
        var filterData = val;
        that.postListData.name = filterData[1].value;
        that.postListData.state = filterData[0].value;
      } else {
        that.postListData.name = '';
        that.postListData.state = '';
      }
      that.initData();
    },
    reload: function reload() {
      for (var i = 0; i < this.filter.length; i++) {
        this.filter[i].value = '';
      }
      this.postListData.name = '';
      this.postListData.state = '';
      this.currentPage = 1;
      this.initData();
    }
  }
};